import React from 'react'
import style from './footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
// Import neccessary site components, try to maintain alphabetical order
import Link from 'shared/link';

export default () => (
	<footer className={style.footer}>
		<div className={style.mainContainer}>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className={[style.innerContainer, style.bigLinks, "cell small-12 medium-3"].join(' ')}>
						<Link className={style.bigLink} to="/telehealth-visits/">Telehealth Visits</Link>
						<hr className={style.hr} />
						<Link className={style.bigLink} to="/request-appointment/">Request Appointment</Link>
						<hr className={style.hr} />
						<Link className={style.bigLink} to="/patient-portal/">FollowMyHealth</Link>
						<hr className={style.hr} />
						<Link className={style.bigLink} to="/about/faqs/">Help & Questions?</Link>
						<hr className={style.hr} />
						<Link className={style.bigLink} to="/patient-info/covid-19/">COVID-19</Link>
					</div>
					<div className={[style.innerContainer, style.smallLinks, "cell small-12 medium-3"].join(' ')}>
						<Link className={style.smallLink} to="/careers/">Careers</Link>
						<Link className={style.smallLink} to="/topic/news/">Newsroom</Link>
						{/*<Link className={style.smallLink} to="/employees/">Employees</Link>
						<Link className={style.smallLink} to="/testimonials/">What Our Patients Are Saying</Link>*/}
						<Link className={style.smallLink} to="/contact-us/">Contact Us</Link>
						<Link className={style.smallLink} to="/search/">Search</Link>
					</div>
					<div className={[style.innerContainer, "cell small-12 medium-offset-1 medium-2"].join(' ')}>
						<div className={style.title}>Main Office</div>
						222 22nd Avenue North<br/>
						Nashville, TN 37203<br/>
						<Link className={style.directions} href="https://www.google.com/maps/dir//Heritage+Medical+Associates,+222+22nd+Ave+N,+Nashville,+TN+37203/@36.1526594,-86.8063282,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x886466be5ac1d977:0x8808206bbf23e7d4!2m2!1d-86.8041395!2d36.1526551" target="_blank">Directions</Link>
					</div>
					<div className={[style.innerContainer, "cell small-12 medium-2 end"].join(' ')}>
						<div className={style.title}>Phone</div>
						<Link to="tel:+16292552139" external={true}>629-255-2139</Link>
					</div>
				</div>
			</div>
		</div>
		<div className={style.copyright}>
			<div className="grid-container">
				<div className={[style.inner, "grid-x grid-margin-x"].join(' ')}>
					<div className="cell medium-2 small-12">
						<div className={style.social}>
							<Link href="https://www.facebook.com/HeritageMedicalAssociates" target="_blank"><FontAwesomeIcon className={style.icon} icon={faFacebookF} title="Visit our Facebook" /></Link>
							<Link href="https://www.youtube.com/user/HeritageMedicalAssoc?feature=watch" target="_blank"><FontAwesomeIcon className={style.icon} icon={faYoutube} title="Visit our YouTube" /></Link>
							<Link href="https://www.linkedin.com/company/heritage-medical-associates-p.c./" target="_blank"><FontAwesomeIcon className={style.icon} icon={faLinkedinIn} title="Visit our Linked In" /></Link>
						</div>
					</div>
					<div className="cell small-12 medium-10 text-center medium-text-right">
						&copy;{new Date().getFullYear()} Heritage Medical Associates.&nbsp;&nbsp;All rights reserved.<br />&nbsp;&nbsp;<Link to="/privacy-policy/">Privacy Policy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/hipaa/">HIPAA Notice</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/good-faith-estimate/">Good Faith Estimate</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/no-surprises-act/">Patient Billing Rights</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/aco-beneficiary-notice/">ACO Beneficiary Notice</Link>
					</div>
				</div>
			</div>
		</div>
	</footer>
)